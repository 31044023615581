<template>
  <footer class="info-footer">
    <div class="container-fluid">
      <!-- <div class="footer-upper">
        <div class="footer-newsletter row">
          <div class="footer-newsletter__content col-lg-6 offset-lg-3">
            <h4>Newsletter abonnieren</h4>

            <div class="footer-newsletter__content--intro">
              <p>
                Need a break from everyday life? The Quellenhotel Heiltherme Bad
                Waltersdorf newsletter will inspire you.
              </p>
              <div>
                <input
                  type="email"
                  name="mailholder"
                  placeholder="Ihre E-Mail-Adresse"
                />
                <a href="#" class="button blue">Anmelden</a>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="footer-lower container">
        <div>
          <!-- <h2 class="font-design">
            wellbein<span class="last-letter">g</span>
          </h2> -->
        </div>
        <div class="contact">
          <a href="mailto:info@kleopatramassage-beauty.com">
            <img
              src="../assets/mail_736506bf.svg"
              alt="eMail Icon"
              data-lazy-src="../assets/mail_736506bf.svg"
              data-ll-status="loaded"
              class="entered lazyloaded"
              width="auto"
              height="45"
            /><noscript
              ><img
                src="../assets/mail_736506bf.svg"
                alt="eMail Icon"
                height="45"
                width="auto"
            /></noscript>
            info@kleopatramassage-beauty.com
          </a>
          <a href="tel:+436601021010" style="font-size: 1rem !important; 
  font-family: Arial, Helvetica, sans-serif;">
            <img
              src="../assets/phone_bead07a6.svg"
              alt="Phone Icon"
              data-lazy-src="../assets/phone_bead07a6.svg"
              data-ll-status="loaded"
              class="entered lazyloaded"
              width="auto"
              height="45"
              style="max-height: 2em;"
            /><noscript
              ><img
                src="../assets/phone_bead07a6.svg"
                alt="Phone Icon"
                height="45"
                width="auto"
            /></noscript>
            <!-- +43(0)55723888 562
            <br />
            +43(0)660 6128341 -->
            +43(0)660 10 2 10 10
          </a>
        </div>
        <div class="row align-items-center information">
          <div class="col-12 col-lg-4 address">
            <h4>{{ $t('You can find us here') }}</h4>
            <p>
              Messestraße 1, 6850 Dornbirn.
              <br />
              Im Panoramahaus Dornbirn.
            </p>
            <router-link to="/google-map" target="" class="button">
              <span>{{ $t('Getting there') }}</span>
            </router-link>
          </div>
          <div class="col-12 col-lg-4 request">
            <h4>{{ $t('Any Questions?') }}</h4>
            <p>
              {{ $t('We are happy') }} <br />
              {{ $t('to help you!') }}
            </p>
            <router-link to="/contactUs" class="button login">{{
              $t('Contact us')
            }}</router-link>
          </div>
          <div class="col-12 col-lg-4 agb-und-co">
            <div class="menu-footer-en-container">
              <h4>{{ $t('Links') }}</h4>

              <ul id="menu-footer-en" class="nav">
                <li v-for="item of list" :key="item.id">
                  <router-link
                    :to="'/page/' + item.slug"
                    class="course-overlay"
                  >
                    {{ $getByLang(item.name) }}</router-link
                  >
                </li>
                <li>
                  <router-link :to="'/faqs'" class="course-overlay">
                    {{ $t('Faqs') }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="socials">
              <a
                href="https://www.facebook.com/"
                target="_blank"
                class="social-link"
              >
                <img
                  src="../assets/fb-social-icon.svg"
                  alt="Facebook"
                  data-lazy-src="../assets/fb-social-icon.svg"
                  data-ll-status="loaded"
                  class="entered lazyloaded"
                  width="35.715"
                  height="35.715"
                /><noscript
                  ><img
                    width="35.715"
                    height="35.715"
                    src="../assets/fb-social-icon.svg"
                    alt="Facebook"
                /></noscript>
              </a>
              <a
                href="https://www.instagram.com/"
                target="_blank"
                class="social-link"
              >
                <img
                  src="../assets/insta-social-icon.svg"
                  alt="Instagram"
                  data-lazy-src="../assets/insta-social-icon.svg"
                  data-ll-status="loaded"
                  class="entered lazyloaded"
                  width="35.483"
                  height="35.483"
                /><noscript
                  ><img
                    width="35.483"
                    height="35.483"
                    src="../assets/insta-social-icon.svg"
                    alt="Instagram"
                /></noscript>
              </a>
              <a
                href="https://www.pinterest.de/"
                target="_blank"
                class="social-link"
              >
                <img
                  src="../assets/pinterest-social-icon.svg"
                  alt="Pinterest"
                  data-lazy-src="../assets/pinterest-social-icon.svg"
                  data-ll-status="loaded"
                  class="entered lazyloaded"
                  width="35.655"
                  height="35.655"
                /><noscript
                  ><img
                    width="35.655"
                    height="35.655"
                    src="../assets/pinterest-social-icon.svg"
                    alt="Pinterest"
                /></noscript>
              </a>
              <a
                href="https://www.youtube.com/channel/"
                target="_blank"
                class="social-link"
              >
                <img
                  src="../assets/yt-social-icon.svg"
                  alt="Youtube"
                  data-lazy-src="../assets/yt-social-icon.svg"
                  data-ll-status="loaded"
                  class="entered lazyloaded"
                  width="35.715"
                  height="35.715"
                /><noscript
                  ><img
                    width="35.715"
                    height="35.715"
                    src="../assets/yt-social-icon.svg"
                    alt="Youtube"
                /></noscript>
              </a>
            </div>
          </div>
        </div>
        <div class="partner">
          <a href="#" target="_blank">
            <img
              class="lazyload"
              data-src="../assets/heiltherme_steiermark_logo_d52f165e.svg"
              src="../assets/heiltherme_steiermark_logo_d52f165e.svg"
              data-loaded="true"
            />
          </a>
          <a href="#" target="_blank">
            <img
              class="lazyload"
              data-src="../assets/heiltherme_oesterreich_logo_765c53b3.svg"
              src="../assets/heiltherme_oesterreich_logo_765c53b3.svg"
              data-loaded="true"
            />
          </a>
          <a href="#" target="_blank">
            <img
              class="lazyload"
              data-src="../assets/heiltherme_thermenland_logo_134b3525.svg"
              src="../assets/heiltherme_thermenland_logo_134b3525.svg"
              data-loaded="true"
            />
          </a>
          <a href="#" target="_blank">
            <img
              class="lazyload"
              data-src="../assets/heiltherme_therme_plus_logo_0c795564.png"
              src="../assets/heiltherme_therme_plus_logo_0c795564.png"
              data-loaded="true"
            />
          </a>
        </div>
      </div>
    </div>
  </footer>
  <!-- <footer>
    <div class="footer container">
      <div class="row">
        <div class="col-md-3 col-xs-12 footer-left fborder">
          <div class="widget">
            <h5 class="widget_title">{{ this.$t('Links') }}</h5>

            <ul>
              <li v-for="item of list" :key="item.id">
                <router-link :to="'/page/' + item.slug">{{
                  $getByLang(item.name)
                }}</router-link>
              </li>
              <li>
                <router-link :to="'/faqs'" class="course-overlay">
                  {{ $t('Faqs') }}</router-link
                >
              </li>
              <li>
                <router-link :to="'/blog'" class="course-overlay">
                  {{ $t('blog') }}</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-5 col-xs-12 fborder">
          <div class="widget">
            <div class="logo-widget">
              <div class="logo">
                <router-link to="/" class="course-overlay">
                  <img
                    src="../assets/logo.png"
                    style="height: 100%; width: 163px;"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4  col-xs-12 footer-right fborder">
          <div class="widget store-btns">
            <h5 class="widget_title">{{ $t('CONTACT INFO') }}:</h5>
            <ul>
              <li>
                <a
                  href="tel://+201090494446"
                  class="contact-icon"
                  style="font-family: initial;"
                  >{{ $t('Phone') }}: +201090494446</a
                >
              </li>

              <li>
                <a href="mailto:info@kleopatramassage-beauty.com" class="contact-icon"
                  >{{ $t('Email') }}: info@kleopatramassage-beauty.com</a
                >
              </li>
            </ul>
            <div>
              <a href="#" class="google-store">
                <img src="../assets/google-store.svg" alt="" />
              </a>
              <a href="#" class="app-store">
                <img src="../assets/app-store.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <p class="text-center">
        Near us
        <br />
        CACTUS BASCHARAGE 250 Metres <br />
        BOFFERDING BASCHARAGE 300 Meters
        <br />
        ÉCOLE PRIMAIRE OP ACKER 50 Meters
        <br />
        KULTURHAUS KÄERJENGER TREFF 50 Meters
      </p>
    </div>
    <div class="footer2 container">
      <div class="row">
        <div class="col-md-4">
          <p style="font-size: 14px; text-transform: uppercase;">
            <a href="https://alwanlab.com" target="_blank">AlwanLab</a> © 2021.
            All Rights Reserved.
          </p>
        </div>
        <div class="col-md-8">
          <div class="socials-widget" style="padding-top: 0;">
            <div class="socials_wrap">
              <a target="_blank" href="https://www.facebook.com/egyptamassage/">
                <span style="background-color:#3b5998;" class="social_icon"
                  ><i class="fa fa-facebook"></i>
                </span>
              </a>
              <a target="_blank" href="https://twitter.com/EgyptaMassage">
                <span style="background-color:#1da1f2;" class="social_icon"
                  ><i class="fa fa-twitter"></i>
                </span>
              </a>
              <a target="_blank" href="https://www.instagram.com/egypta_massage"
                ><span style="background-color:#0077b5;" class="social_icon"
                  ><i class="fa fa-instagram"></i></span
              ></a>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCspYFFphGmeaK7fXvDbgU-Q"
              >
                <span style="background-color:#cd201f;" class="social_icon"
                  ><i class="fa fa-youtube"></i>
                </span>
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/in/egypta-massage-a805491a8/"
              >
                <span style="background-color:#0077b5;" class="social_icon"
                  ><i class="fa fa-linkedin"></i>
                </span>
              </a>
              <a target="_blank" href="whatsapp://send?phone=+201090494446"
                ><span style="background-color:#34af23;" class="social_icon"
                  ><i class="fa fa-whatsapp"></i></span
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer> -->
</template>

<script>
export default {
  name: 'app-footer',
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.$http.get(`page?typePage=footer`).then(
      (res) => {
        this.list = res.data;
      },
      (err) => {
        console.log(err);
      },
    );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>

import moment from "moment";
import Vue from "vue";
import { HTTP, baseUploadURL, socket } from "./axios";

Vue.prototype.$baseUploadURL = baseUploadURL;
Vue.prototype.$http = HTTP;
Vue.prototype.$eventHub = new Vue();
Vue.prototype.$socket = socket;

if (!localStorage.lang) {
  localStorage.lang = "de";
}
Vue.prototype.$getByLang = (text: any) => {
  if (localStorage.lang && text && JSON.parse(text)) {
    return JSON.parse(text)[localStorage.lang];
  } else {
    return "";
  }
};

Vue.prototype.$textSlice = (text: any, limit = 150) => {
  if (text.length > 150) {
    return text.slice(0, limit) + "...";
  } else {
    return text;
  }
};

Vue.prototype.$codePadding = (text, length = 6) => {
  return String(text).padStart(length, "0");
};

Vue.prototype.$durationFormat = (value) => {
  if (value) {
    const duration = moment(String(value));

    return duration.format("DD-MM-YYYY");
  } else {
    return "";
  }
};


Vue.prototype.$durationFormatFullDate = (value) => {
  if (value) {
    const duration = moment(String(value));

    return duration.format('DD-MM-YYYY hh:mm A');
  } else {
    return '';
  }
};

Vue.prototype.$durationFormat = (value) => {
  if (value) {
    const duration = moment(String(value));

    return duration.format("DD-MM-YYYY");
  } else {
    return "";
  }
};

Vue.prototype.$durationFormatStartToEnd = (start,end) => {
  if (start && end) {
    const s = moment(String(start));
    const e = moment(String(end));

    return s.format("HH:mm") +' - ' + e.format("HH:mm");
  } else {
    return "";
  }
};

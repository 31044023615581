<template>
  <div style="margin-top: -146px;" class="oppppdcdc">
    <video
      id="media_display_1355231"
      loop="loop"
      autoplay="autoplay"
      playsinline="playsinline"
      :src="$baseUploadURL + homevideo"
      style="width: 100%;"
    />

    <div v-if="loadx" class="container xdtftrdtrdt">
      <welcome />
    </div>
    <products
      v-if="services && services.length > 0"
      :list="services"
      :list2="massage"
      :service="false"
      :ssssxxxshow="false"
    />

    <conactus />
  </div>
</template>

<script>

import welcome from "./home/welcome";
import conactus from "./home/conactus";
import products from "./home/products";


export default {
  components: {

    welcome,
    conactus,
    products,

  },
  data() {
    return {
      visible: false,
      services: [],
      massage: [],
      testimonials: [],
      loadx: false,
      galleryList: [],
      blogList: [],
      userList: [],
      homevideo: null,
    };
  },

  created() {
    this.$http.get("sections").then(
      (res) => {
        this.services = res.data.data;

        this.loadx = true;
      },
      (err) => {
        console.log(err);
      }
    );

    // this.$http.get("users").then(
    //   async (res) => {
    //     this.userList = await res.data;
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );

    // this.$http.get("gallery").then(
    //   (res) => {
    //     this.galleryList = res.data;
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );

    // this.$http.get("blog").then(
    //   (res) => {
    //     this.blogList = res.data;
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );

    this.$http.get(`home`).then(
      (res) => {
        this.homevideo = res.data.homevideo;
      },
      (err) => {}
    );
  },

  head: {
    // title: {
    // inner: 'It will be a pleasure',
    // },
    // Meta tags
    meta: [
      { name: "application-name", content: "Kleopatra Massage & Beauty" },
      {
        name: "description",
        content: "Kleopatra Massage & Beauty",
        id: "desc",
      }, // id to replace intead of create element
      // ...
      // Twitter
      { name: "twitter:title", content: "Kleopatra Massage & Beauty" },
      // with shorthand
      {
        n: "twitter:description",
        c: "Kleopatra Massage & Beauty",
      },
      // ...
      // Google+ / Schema.org
      { itemprop: "name", content: "Kleopatra Massage & Beauty" },
      { itemprop: "description", content: "Kleopatra Massage & Beauty" },
      // ...
      // Facebook / Open Graph
      // { property: 'fb:app_id', content: '123456789' },
      { property: "og:title", content: "Kleopatra Massage & Beauty" },
      // with shorthand
      {
        p: "og:image",
        c: "https://kleopatramassage-beauty.com/image/logo.png",
      },
      // ...
    ],
  },
};
</script>

<style scoped>
.section-title {
  padding-left: 45px;
  position: relative;
  margin-bottom: 56px;
}
.section-title .subtitle {
  font-size: 13px;
  text-transform: uppercase;
  display: inline-block;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  left: 10px;
  top: 44px;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  line-height: 1;
  color: #81ecec;
  font-weight: bold;
}
.section-title h2 {
  margin-top: -4px;
  margin-bottom: 17px;
  color: #81ecec;
}
.section-title .subtitle::after {
  position: absolute;
  left: -40px;
  top: 44%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  width: 32px;
  height: 1px;
  background-color: #81ecec;
}

.pb-90 {
  padding-bottom: 90px;
}
.pt-120 {
  padding-top: 120px;
}

.single-specialist {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}

.single-specialist img {
  width: 100%;
  height: 50vh;
}

.single-specialist .content {
  text-align: center;
  padding-top: 12px;
}

.homeGallery .col-sm-3,
.homeGallery .col-sm-4 {
  padding: 0;
}

.single-post {
  margin-bottom: 30px;
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.05);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.05);
}
.single-post .post-thumb-wrap {
  position: relative;
}
.single-post .post-thumbnail img {
  width: 100%;
}

.single-post .post-thumb-wrap .posted-on {
  position: absolute;
  left: 30px;
  bottom: 15px;
  width: 50px;
  height: 50px;
  font-size: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  line-height: 1.19;
  letter-spacing: 1px;
}

.single-post .post-thumb-wrap .posted-on a {
  display: block;
  color: #fff;
  text-align: center;
}

.single-post .post-thumb-wrap .posted-on .day {
  display: block;
  font-size: 16px;
  letter-spacing: normal;
  font-weight: 600;
}

.single-post .post-content {
  padding: 33px 30px 40px;
}

.single-post .post-content .post-title {
  margin-bottom: 10px;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;

  font-size: 1.5rem;
  color: #0f0d0d;
}
#media_display_1355231 {
  height: 100vh;
  width: 100vw;
  -o-object-fit: cover;
  object-fit: cover;
  vertical-align: top;
  /* margin-top: -78px; */
}

@media (max-width: 767.98px) {
  #media_display_1355231 {
    height: 100% !important;
  }

  .xdtftrdtrdt {
    margin: 0;
    width: 100% !important;
  }
}
</style>

<template>
  <div class=" welcome">
   
    <div class="row">
     
      <div class="col-md-12">
        <div class="block-content wow fadeInRight animated animated">
          <div class="border-title">
          
          </div>
          <p style="color:#3e454c;font-size: 22px;" class="dxfcgthbjnkm">
            {{ $t('w1') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'welcome',
  data() {
    return {
      homevideo: null,
      youtube: null,
    };
  },
  created() {
   
    
  },
};
</script>

<style>
@media (max-width: 767.98px)  {
  
}
</style>

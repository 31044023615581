<template>
  <div class="container conactus">
    <!-- <div id="page-header">
              <h2 class="font-design mb-2  mt-1 ">
                conact U<span class="last-letter">s</span>
              </h2>
            </div> -->
    <div id="page-header">
      <h2 class="  mb-5  mt-1 xsxxsx xsxxs2">
        {{ $t("Conact Us") }}
      </h2>
    </div>
    <div class="row">
      <!-- <div class="col-md-5">
        <img class="img-fluid" src="../../assets/home-contactus.png" />
      </div> -->
      <div class="col-md-12">
        <div class="">
          <!-- <h3 style="text-align: left;">
            {{ $t('Conact Us') }}
          </h3> -->

          <form @submit.prevent="recaptcha" method="post">
            <div class="row ">
              <div class="col-md-6 mb-3">
                <input
                  type="text"
                  style="height: 54px;"
                  class="form-control"
                  :placeholder="$t('Enter Name')"
                  v-model="body.pname"
                />
              </div>
              <div class="col-md-6 mb-3 cssphoneadd">
                <!-- <input type="text" style="height: 54px;" class="form-control" :placeholder="$t('Enter Phone')"
                  v-model.trim="body.phone" /> -->

                <vue-tel-input
                  v-model.trim="body.phone"
                  v-bind="{
                    mode: 'international',
                  }"
                  :allCountries="allCountries"
                ></vue-tel-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <input
                  type="text"
                  style="height: 54px;"
                  class="form-control"
                  :placeholder="$t('Enter subject')"
                  v-model="body.subject"
                />
              </div>
              <div class="col-md-6 mb-3">
                <input
                  type="text"
                  style="height: 54px;"
                  class="form-control"
                  :placeholder="$t('Enter Email')"
                  v-model="body.email"
                />
              </div>
            </div>
            <div class="form-group">
              <textarea
                class="form-control"
                :placeholder="$t('Enter text')"
                rows="6"
                v-model="body.message"
              ></textarea>
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-more">
                {{ $t("send") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "conactus",
  data() {
    return {
      body: {
        email: null,
        subject: null,
        phone: null,
        message: null,

        pname: null,
      },

      allCountries: [
        ["Austria (Österreich)", "at", "43"],
        ["Germany (Deutschland)", "de", "49"],
        ["Switzerland (Schweiz)", "ch", "41"],
        ["Liechtenstein", "li", "423"],
      ].map(([name, iso2, dialCode]) => ({
        name,
        iso2: iso2.toUpperCase(),
        dialCode,
      })),
    };
  },
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      // await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      // const token = await this.$recaptcha('login');

      // Do stuff with the received token.
      await this.save();
    },
    save() {
      if (
        this.body.pname &&
        this.body.email &&
        this.body.subject &&
        this.body.phone &&
        this.body.message
      ) {
        this.$eventHub.$emit("showSpinner", true);
        this.$http.post(`contactUs`, this.body).then(
          (res) => {
            this.$eventHub.$emit("showSpinner", false);
            this.$swal.fire({
              icon: "success",
              title: this.$t("Success"),
              text: this.$t("Success Done"),
              showConfirmButton: false,
              timer: 1500,
            });
            this.body = {
              pname: null,
              email: null,
              subject: null,
              phone: '+43',
              message: null,
            };
          },
          (err) => {
            this.$eventHub.$emit("showSpinner", false);
            this.$swal.fire({
              icon: "error",
              title: this.$t("Error"),
              text: this.$t("Something has gone wrong"),
              showConfirmButton: false,
              timer: 1500,
            });
          }
        );
      }
    },
  },
};
</script>

<style>
.cssphoneadd input,
.cssphoneadd .vti__dropdown {
  height: 54px;
  color: #7f8d99;
  border-color: #ecf1f5 !important;
  background-color: #ecf1f5;
  border-radius: 2px;
  padding: 1.05em 7px;
  border: 2px solid #ddd;
}
.cssphoneadd .vue-tel-input {
  border: none !important;
}
</style>

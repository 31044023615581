import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';

// Install BootstrapVue
Vue.use(BootstrapVue);
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.use(VueTelInput);

// main.js
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

import VueHead from 'vue-head'
 
Vue.use(VueHead,{
    
})

// import { VueReCaptcha } from 'vue-recaptcha-v3';

// For more options see below
// Vue.use(VueReCaptcha, { siteKey: '6LfCHTMbAAAAAJl512pFG_Cb8mpUVT62xvoK0VUy' });


// Install PrimeVue
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
Vue.use(PrimeVue, { ripple: true });
Vue.use(ToastService);
Vue.use(ConfirmationService);
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";


import CascadeSelect from 'primevue/cascadeselect';
import Sidebar from 'primevue/sidebar';
import Dropdown from 'primevue/dropdown';


Vue.component("CascadeSelect", CascadeSelect);
Vue.component("Sidebar", Sidebar);
Vue.component("Dropdown", Dropdown);
<template>
  <div id="app" style="overflow-x: hidden;">
    <div id="loading-area" v-if="loading"></div>
    <app-header v-if="show"></app-header>

    <router-view />

    <app-footer v-if="show" style="overflow-x: hidden;"></app-footer>

    <!-- <div
      @click="$router.push('/cart')"
      v-if="countItem > 0"
      style="
              position: fixed; 
              bottom: 11%; left: 4%; 
              color: rgb(175, 0, 45);
              font-weight: 400;
              font-size: 4.765em;
              z-index: 999;
           "
    >
      <i class="fa fa-cart-plus"></i>
      <span
        class="badge badge-pill badge-info"
        style="font-size: 16px;position: absolute;top: 20px;right: -17px;"
        >{{ countItem }}</span
      >
    </div> -->
  </div>
</template>

<script>
import AppHeader from '@/layout/header.vue';
import AppFooter from '@/layout/footer.vue';
export default {
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      loading: true,
      show: true,
      countItem: 0,
    };
  },
  created() {
    // this.loading
    this.$eventHub.$on('showSpinner', (val) => {
      this.loading = val;
    });
    // this.$eventHub.$emit('showSpinner', true);

    const href = window.location.href;
    if (
      href.startsWith('https://kleopatramassage') == false &&
      href.startsWith('http://localhost') == false
    ) {
      window.location.href = 'https://kleopatramassage-beauty.com';
    }
    if (href.includes('/giftPrint/')) {
      this.show = false;
    }
  },
  methods: {
    getNewCount() {
      let itemsList = localStorage.itemsList
        ? JSON.parse(localStorage.itemsList)
        : [];

      this.countItem = itemsList
        .map((item) => item.quantity)
        .reduce((x, y) => x + y, 0);
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 3500);

    this.getNewCount();
    this.$eventHub.$on('addItem', () => {
      this.getNewCount();
    });
  },
};
</script>

<style>
#loading-area {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 1;
  z-index: 9999;
  background-image: url(./assets/loading.svg);
  background-repeat: no-repeat;
  background-size: 20vw;
  background-position: center;
}
</style>

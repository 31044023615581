<template>
  <div class=" products">
    <div style="background: #99836a;">
      <div class="container xsx">
        <div class="text-center mb-5" v-if="!service">
          <div id="page-header">
            <h2 class=" mb-2  mt-1 xsxxsx">
              {{ $t("Our Services") }}
            </h2>
          </div>
        </div>
        <div class="row mb-5" v-if="list && list.length > 0">
          <div class="col-md-4 col-sm-12 col-xs-12 col-lg-4">
            <div class="ed-course-in">
              <router-link to="/service/massage" class="course-overlay">
                <img class="mimg" :src="body.image1" alt="" />
                <span>{{ $t("Massage") }}</span>
              </router-link>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 col-lg-4">
            <div class="ed-course-in">
              <router-link
                to="/service/Feel-good-packages"
                class="course-overlay"
              >
                <img class="mimg" :src="body.image6" alt="" />
                <span>{{ $t("Feel-good packages") }}</span>
              </router-link>
            </div>
          </div>

          <div class="col-md-4 col-sm-12 col-xs-12 col-lg-4">
            <div class="ed-course-in">
              <router-link to="/service/cosmetics" class="course-overlay">
                <img class="mimg" :src="body.image2" alt="" />
                <span>{{ $t("Cosmetics") }}</span>
              </router-link>
            </div>
          </div>

          <div
            class="col-md-12 col-sm-12 col-xs-12 col-lg-12 oldfonts"
            style="color:aliceblue;font-size: 18px;"
          >
            {{ $t("serv2") }}
            <br />
            <br />
          </div>

          <div class="col-md-4 col-sm-12 col-xs-12 col-lg-4">
            <div class="ed-course-in">
              <router-link
                to="/service/manicure-pedicure"
                class="course-overlay"
              >
                <img class="mimg" :src="body.image3" alt="" />
                <span>{{ $t("Manicure & Pedicure") }}</span>
              </router-link>
            </div>
          </div>

          <!-- <div class="col-md-4 col-sm-12 col-xs-12 col-lg-4">
            <div class="ed-course-in">
              <router-link to="/service/body-peeling" class="course-overlay">
                <img class="mimg" :src="body.image4" alt="" />
                <span>{{ $t("body peeling") }}</span>
              </router-link>
            </div>
          </div> -->

          <div class="col-md-4 col-sm-12 col-xs-12 col-lg-4">
            <div class="ed-course-in">
              <router-link to="/service/body-treatments" class="course-overlay">
                <img class="mimg" :src="body.image5" alt="" />
                <span>{{ $t("Body treatments") }}</span>
              </router-link>
            </div>
          </div>

          <div class="col-md-4 col-sm-12 col-xs-12 col-lg-4">
            <div class="ed-course-in">
              <router-link to="/packages" class="course-overlay">
                <img class="mimg" :src="body.image7" alt="" />
                <span>{{ $t("OUR Offer") }}</span>
              </router-link>
            </div>
          </div>

          <!-- 111 -->

          <div
            class="col-md-12 col-sm-12 col-xs-12 col-lg-12 oldfonts"
            style="color:aliceblue;font-size: 18px;"
          >
            {{ $t("serv1") }}
            <br />
            <br />
          </div>

          <div class="col-md-4 col-sm-12 col-xs-12 col-lg-4">
            <div class="ed-course-in">
              <router-link to="/blog" class="course-overlay">
                <img class="mimg" :src="body.image8" alt="" />
                <span>{{ $t("Our Production") }}</span>
              </router-link>
            </div>
          </div>

          <div class="col-md-4 col-sm-12 col-xs-12 col-lg-4">
            <div class="ed-course-in">
              <router-link to="/gift" class="course-overlay">
                <img class="mimg" :src="body.image9" alt="" />
                <span>{{ $t("Gift") }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-if="ssssxxxshow">
      <div id="page-header"></div>
      <div class="row" v-if="list2 && list2.length > 0">
        <div v-for="item of list2" :key="item.id" class="col-md-4">
          <div class="home-top-cour row">
            <!--POPULAR COURSES IMAGE-->
            <div class="col-md-4">
              <router-link :to="'/massage/' + item.slug">
                <img :src="baseUploadURL + item.image" alt=""
              /></router-link>
            </div>
            <!--POPULAR COURSES: CONTENT-->
            <div class="col-md-8 home-top-cour-desc">
              <router-link :to="'/massage/' + item.slug">
                <h3>{{ $getByLang(item.name) }}</h3>
              </router-link>

              <div class="hom-list-share">
                <ul>
                  <li>
                    <router-link :to="'/booking/' + item.id">
                      <i class="fa fa-gift" aria-hidden="true"></i
                      >{{ $t("BOOK") }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center" style="margin-top: 30px">
        <a class="btn btn-more" v-if="!noMore" @click="getMore">{{
          $t("More Massages")
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "products",
  props: {
    list: [],
    list2: [],
    service: null,
    ssssxxxshow: true,
  },
  data() {
    return {
      baseUploadURL: this.$baseUploadURL,
      noMore: false,
      skip: 6,
      body: {
        image1: null,
        image2: null,
        image3: null,
        image4: null,
        image5: null,
        image6: null,
        image7: null,
        image8: null,
        image9: null,
      },
    };
  },
  methods: {
    getMore() {
      this.$http
        .get(
          `massage?skip=${this.skip}&limit=6${
            this.service ? "&service=" + this.service : ""
          }`
        )
        .then(
          (res) => {
            for (const item of res.data) {
              this.list2.push(item);
            }
            this.skip = this.skip + 6;
            if (res.data.length < 66) this.noMore = true;
          },
          (err) => {
            console.log(err);
          }
        );
    },
    getPriceByService(prices) {
      const list = [];
      for (const item of prices) {
        if (item.services.find((el) => el.id == this.service)) {
          list.push(item);
        }
      }
      return list;
    },
    getData() {
      this.$http.get(`home`).then(
        (res) => {
          this.body.image1 = this.$baseUploadURL + res.data.image1;
          this.body.image2 = this.$baseUploadURL + res.data.image2;
          this.body.image3 = this.$baseUploadURL + res.data.image3;
          this.body.image4 = this.$baseUploadURL + res.data.image4;
          this.body.image5 = this.$baseUploadURL + res.data.image5;
          this.body.image6 = this.$baseUploadURL + res.data.image6;
          this.body.image7 = this.$baseUploadURL + res.data.image7;
          this.body.image8 = this.$baseUploadURL + res.data.image8;
          this.body.image9 = this.$baseUploadURL + res.data.image9;
        },
        (err) => {}
      );
    },
  },
  created() {
    this.getData();
  },
};
</script>

// en, de, fr, it , lu ,pt
import en from './json/en.json';
import de from './json/de.json';
import fr from './json/fr.json';
import it from './json/it.json';
import lb from './json/lb.json';
import pt from './json/pt.json';

export default {
  en,
  de,
  fr,
  it,
  lb,
  pt,
};
